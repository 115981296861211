<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{
                    $t("coaCategory.nameKh")
                }}</label>
                <input
                    ref="acc_cat_name_kh"
                    v-model="model.acc_cat_name_kh"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('acc_cat_name_kh') }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('acc_cat_name_kh')"
                >
                    {{ errors.first("acc_cat_name_kh") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("coaCategory.nameEn")
                }}</label>
                <input
                    v-model="model.acc_cat_name_en"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('acc_cat_name_en') }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('acc_cat_name_en')"
                >
                    {{ errors.first("acc_cat_name_en") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("coaCategory.categoryType")
                }}</label>
                <select
                    v-model="model.acc_cat_type"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('acc_cat_type') }"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(cat_type, index) in categoryTypes"
                        :key="index"
                        :value="cat_type"
                        >{{ cat_type }}</option
                    >
                </select>
                <div class="invalid-feedback" v-if="errors.has('acc_cat_type')">
                    {{ errors.first("acc_cat_type") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{
                    $t("coaCategory.reportOrder")
                }}</label>
                <input
                    v-model.number="model.report_order_id"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('report_order_id') }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('report_order_id')"
                >
                    {{ errors.first("report_order_id") }}
                </div>
            </div>
        </div>
        <div class="mt-3">
            <ts-checkbox v-model="model.bspl">{{
                $t("coaCategory.bspl")
            }}</ts-checkbox>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "coaCategoryForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                acc_cat_name_kh: null,
                acc_cat_name_en: null,
                acc_cat_type: null,
                report_order_id: null,
                bspl: false
            }
        };
    },
    computed: {
        ...mapState("accounting/coaCategory", ["edit_data", "categoryTypes"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("accounting/coaCategory/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.clearInput();
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("accounting/coaCategory/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("accounting/coaCategory/update", {
                    id: this.edit_data.acc_cat_id,
                    data: this.model
                })
                .then(response => {
                   this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function() {
                this.$refs.acc_cat_name_kh.focus();
            });
            if (!isEmpty(this.edit_data)) {
                this.model.acc_cat_name_kh = this.edit_data.acc_cat_name_kh;
                this.model.acc_cat_name_en = this.edit_data.acc_cat_name_en;
                this.model.acc_cat_type = this.edit_data.acc_cat_type;
                this.model.report_order_id = this.edit_data.report_order_id;
                this.model.bspl = this.edit_data.bspl;
            }
        },
        clearInput() {
            this.model.acc_cat_name_kh = null;
            this.model.acc_cat_name_en = null;
            this.model.acc_cat_type = null;
            this.model.report_order_id = null;
            this.model.bspl = false;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CoA CATEGORY",
                desc: not.text
            });
        }
    }
};
</script>
